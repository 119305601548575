import React from "react";
import "./NewsSlider.css";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import SingleNews from "../SingleNews/SingleNews";

// Learning From Cryptopedia Slider

const NewsSliderCarosel = (ImgWidth) => {
  const News = [

    // DynaSys Triumphs Twice at HBL P@SHA ICT Awards 2024
    {
      NewsLink: "/dynasys_triumphs_twice_at_hbl_pasha",
      NewsDate: "Mon 23 Sep, 2024",
      NewsHeading:
        "DynaSys Triumphs Twice at HBL P@SHA ICT Awards 2024",
      NewsDetails:
        "DynaSys Networks, a leading innovator in Pakistan in Internet of Things technology...",
      NewsImage: "/Images/DynaSysTriumphsImage.png",
    },

    // Azercosmos to Offer IoT Solutions Across Its Satcom Network.
    {
      NewsLink: "/azercosmos_offer_iot_solution",
      NewsDate: "Tue 09 July, 2024",
      NewsHeading:
        "Azercosmos to Offer IoT Solutions Across Its Satcom Network..",
      NewsDetails:
        "DynaSys Networks, a technology company with a cutting-edge Internet of Things ecosystem...",
      NewsImage: "/Images/azercosmosNewsImage.png",
    },
    // DynaSys and TSGN Join Forces to Accelerate IoT Growth..
    {
      NewsLink: "/dynasys_tsgn_join_forces",
      NewsDate: "Mon 03 June, 2024",
      NewsHeading: "DynaSys and TSGN Join Forces to Accelerate IoT Growth..",
      NewsDetails:
        "DynaSys Networks, a leading provider of Internet of Things (IoT) solutions, and TS Global Network (TSGN)...",
      NewsImage: "/Images/DynasysTsgnNewsImage.png",
    },
    // IoT Innovation in Pakistan Accelerated - DynaSys and Pronet ink MoU
    {
      NewsLink: "/dynasys_pronet_ink_mou",
      NewsDate: "Mon 20 May, 2024",
      NewsHeading:
        "IoT Innovation in Pakistan Accelerated - DynaSys and Pronet ink MoU",
      NewsDetails:
        "DynaSys Networks (DynaSys), a leader in the IoT space and Pronet, a renowned system integrator in Pakistan...",
      NewsImage: "/Images/dynasysPronetNewsImage.png",
    },
    // Inotech & DynaSys Combine Forces to Commercialize IoT in Pakistan
    {
      NewsLink: "/inotech_dynasys",
      NewsDate: "Tue 06 Feb, 2024",
      NewsHeading:
        "Inotech and DynaSys Combine Forces to Commercialize IoT in Pakistan",
      NewsDetails:
        "Inotech Solutions (Inotech) and DynaSys Networks (DynaSys) have signed a Memorandum...",
      NewsImage: "/Images/inotech-dynasysLanding2.png",
    },
    //  DynaSys Networks and Xiplink Awarded Cellular Backhaul..
    {
      NewsLink: "/dynasys_xiplink",
      NewsDate: "Wed 11 Oct, 2023",
      NewsHeading: "DynaSys Networks and Xiplink Awarded Cellular Backhaul..",
      NewsDetails:
        "XipLink Inc., the technology leader in Wireless Link Optimization, and DynaSys Networks...",
      NewsImage: "/Images/dynasys-xiplink.png",
    },
    //  PTCL & DynaSys Networks Join Hands
    {
      NewsLink: "/ptcl_dynasys",
      NewsDate: "Tue 12 Sep, 2023",
      NewsHeading: "PTCL & DynaSys Networks Join Hands",
      NewsDetails:
        "PTCL and DynaSys Networks have signed two Memoranda of Understandings...",
      NewsImage: "/Images/Ptcl-Dynasys-LandingPageSlider.png",
    },
    // Mous Signing Cermonay
    {
      NewsLink: "/iot_license",
      NewsDate: "Wed 06 Sep, 2023",
      NewsHeading: "DynaSys Networks receives IoT LPWAN License",
      NewsDetails:
        "DynaSys Networks announced today that the Pakistan Telecommunication Authority...",
      NewsImage: "/Images/iot_license_test2.png",
    },
  ];
  return (
    <div className="LandingpageNewsSlider">
      <Swiper
        className=" News_Slider"
        modules={[Navigation, Scrollbar, A11y]}
        spaceBetween={0}
        slidesPerView={2}
        // scrollbar={{
        //   draggable: true,
        //   hide: false,
        //   snapOnRelease: true,
        // }}
        navigation
        breakpoints={{
          // Responsive breakpoints
          150: {
            slidesPerView: 1, // Number of slides to show on mobile
            spaceBetween: 10, // Space between slides on mobile
          },
          550: {
            slidesPerView: 1.5, // Number of slides to show on mobile
            spaceBetween: 20, // Space between slides on mobile
          },
          992: {
            slidesPerView: 2.5, // Number of slides to show on mobile
            spaceBetween: 10, // Space between slides on mobile
          },
          1351: {
            slidesPerView: 2, // Number of slides to show on mobile
            spaceBetween: 10, // Space between slides on mobile
          },
        }}
        pagination={{
          spaceBetween: 0,
        }}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {/* IoT Innovation in Pakistan Accelerated – DynaSys and Pronet ink MoU */}
        {News.map((item) => (
          <SwiperSlide>
            <SingleNews
              newslink={item.NewsLink}
              newsdate={item.NewsDate}
              newshead={item.NewsHeading}
              newsdetails={item.NewsDetails}
              newsimage={item.NewsImage}
              ImgWidth={"276px"}
              Width={"85%"}
            />
          </SwiperSlide>
        ))}

        {/* Press Release 1 Imran Farooq */}

        <SwiperSlide>
          <SingleNews
            newslink={"/imran_farooq_press_release"}
            newsdate={"Fri 25 Aug, 2023"}
            newshead={"Imran Farooq Joins DynaSys Networks"}
            newsdetails={"We welcome our new Chief Technology Officer..."}
            newsimage={"/Images/imranFarooq.png"}
            Width={"85%"}
          />
        </SwiperSlide>

        {/* Press Release 2 disrupt_x_press_release */}

        <SwiperSlide>
          <SingleNews
            newslink={"/disrupt_x_press_release"}
            newsdate={"Tue 08 Aug, 2023"}
            newshead={"Disrupt-X and DynaSys Networks Announce Partnership"}
            newsdetails={"Disrupt-X partners with DynaSys  Networks to fast..."}
            newsimage={"/Images/disruptXnews.webp"}
          />
          <style>
            {`.swiper-pagination{
      color: red;
    },
    .swiper-pagination-bullet {
      color: red;
      background-color: red;
    }
    `}
          </style>
        </SwiperSlide>

        {/* Press Release 3 hamid_nawaz_press_release */}

        {/* <SwiperSlide>
          <SingleNews
            newslink={"/hamid_nawaz_press_release"}
            newsdate={"Thu 25 July, 2023"}
            newshead={"Hamid Nawaz Joins  DynaSys Networks"}
            newsdetails={"We welcome our new  chairman of the board..."}
            newsimage={"/Images/LandingHamidImg.webp"}
            Width={"85%"}
          />
        </SwiperSlide> */}

        {/* Press Release 4 DynaSys Networks goes  international */}

        {/* <SwiperSlide>
          <SingleNews
            newshead={"DynaSys Networks goes  international"}
            newsdate={"Thu 08 June, 2023"}
            newsdetails={"DynaSys Networks opens a new office in  the UAE"}
            newsimage={"/Images/smallVideoImg.webp"}
            Width={"85%"}
          />
        </SwiperSlide> */}
      </Swiper>
    </div>
  );
};

export default function NewsSlider() {
  return (
    <div className="NewsSlider">
      <NewsSliderCarosel />
    </div>
  );
}
